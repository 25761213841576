import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import '../../utils/styles/About.scss';
// import { Typewriter } from 'react-simple-typewriter';

const About = () => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const { ref, inView } = useInView({
    triggerOnce: true,
    onChange: (inView) => {
      if (inView) {
        setHasAnimated(true);
      }
    }
  });

  return (
    <section ref={ref} className="about" id='about'>
        <h1>About section</h1>
      {/* <h1>
        {hasAnimated && (
          <Typewriter
            words={['What is K0la.fr ?']}
            loop={1}
            cursor
            cursorStyle="_"
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        )}
      </h1> */}
      <div>
        <h2></h2>
        {/* <img></img> */}
        <p></p>
      </div>
    </section>
  );
};

export default About;
