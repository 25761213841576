import React, { useEffect, useState } from 'react';
import { Typewriter } from 'react-simple-typewriter';
import '../utils/styles/WelcomeScreen.scss';

const WelcomeScreen = ({ onFinish }) => {
  const [fade, setFade] = useState(false);
  const [translation, setTranslation] = useState('');
  const [translationVisible, setTranslationVisible] = useState(false);

  const translations = [
    "Bienvenue sur K0la.fr",
    "Un site web fait pour ..",
    "rien ^^",
    ""
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(true);
      setTimeout(onFinish, 1000); 
    }, 9500); 
    return () => clearTimeout(timer);
  }, [onFinish]);

  const handleType = (count) => {
    setTranslationVisible(false);
    setTimeout(() => {
      setTranslation(translations[count]);
      setTranslationVisible(true);
    }, 1000);
  };

  return (
    <div className={`welcome-screen ${fade ? 'fade-out' : ''}`}>
      <h1>
        <Typewriter
          words={["Welcome to K0la.fr", "A web site made for ..", "nothing ^^", ""]}
          loop={1}
          cursor
          cursorStyle='_'
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
          onType={(count) => handleType(count)}
        />
      </h1>
      <p className={`translation ${translationVisible ? 'fade-in' : 'fade-out'}`}>{translation}</p>
    </div>
  );
}

export default WelcomeScreen;
