// src/pages/Home.js
import React from 'react';
import Header from '../components/sections/Header';
import About from '../components/sections/About';
import Projects from '../components/sections/Projects';
import Contact from '../components/sections/Contact';
import Footer from '../components/sections/Footer';

const Home = () => {
  return (
    <div>
      <Header />
      <About />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
