import React from 'react';
import '../../utils/styles/Projects.scss';

const Projects = () => {
    return (
        <section className='projects' id='projects'>
        <h1>Projects section</h1>
        </section>
    );
}


export default Projects;