import React from 'react';
import '../../utils/styles/Footer.scss';

const Footer = () => {
    return (
        <footer className='footer'>
        </footer>
    );
}

export default Footer;