import React from 'react';

const Contact = () => {
    return (
        <section className='contact' id='contact'>
        <h1>Contact section</h1>
        </section>
    );
}


export default Contact;