import React from 'react';


const Signup = () => {
    return (
        <div>
        <h1>Signup page</h1>
        </div>
    );
    }

export default Signup;