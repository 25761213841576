import React from 'react';
import { Typewriter } from 'react-simple-typewriter';
import { FaChevronDown } from 'react-icons/fa';
import '../../utils/styles/Header.scss';

const Header = () => {
  return (
    <div className="header" id='header'>
      <h1>
        <Typewriter
          words={['K0la.fr']}
          loop={1}
          cursor
          cursorStyle='_'
          typeSpeed={70}
          deleteSpeed={50}
          delaySpeed={1000}
        />
      </h1>
      <div className="scroll-down">
        <FaChevronDown />
      </div>
    </div>
  );
}

export default Header;
