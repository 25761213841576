import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './utils/i18n';

import Home from './pages/Home';
import Login from './pages/Login';
import Signup from './pages/Signup';
import Download from './pages/Download';
import MentionsLegal from './pages/MentionsLegal';
import NavButtonMenu from './components/Navbar/NavButtonMenu';
import WelcomeScreen from './components/WelcomeScreen';

import './utils/styles/root.scss';

function App() {
  const [showWelcomeScreen, setShowWelcomeScreen] = useState(true);

  const handleWelcomeScreenFinish = () => {
    setShowWelcomeScreen(false);
  };

  return (
    <Router>
      <div className="app-container">
        {showWelcomeScreen && <WelcomeScreen onFinish={handleWelcomeScreenFinish} />}
        {!showWelcomeScreen && (
          <>
            <NavButtonMenu />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/mentions-legal" element={<MentionsLegal />} />
              <Route path="/download" element={<Download />} />
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </>
        )}
      </div>
    </Router>
  );
}

export default App;
