import React from 'react';

const Download = () => {
    return (
        <div>
            <h1>Download Page</h1>
        </div>
    );
}

export default Download;