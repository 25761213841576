// src/components/NavBar/NavButtonMenu.js
import React, { useState } from 'react';
import '../../utils/styles/Navbar/NavButtonMenu.scss';
import Nav from './Nav';

const NavButtonMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const toggleNav = () => {
    if (isOpen) {
      setIsClosing(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsClosing(false);
      }, 600); // Durée de l'animation du fade out + slide up
    } else {
      setIsOpen(true);
    }
  };

  return (
    <>
      <div className={`nav-button-menu ${isOpen ? 'open' : ''}`} onClick={toggleNav}>
        <div className="bar1"></div>
        <div className="bar2"></div>
      </div>
      {(isOpen || isClosing) && <Nav isClosing={isClosing} onLinkClick={toggleNav} />}
    </>
  );
};

export default NavButtonMenu;
