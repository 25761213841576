import React from 'react';


const Login = () => {
    return (
        <div>
        <h1>Login page</h1>
        </div>
    );
    }

export default Login;