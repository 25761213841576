import React, { useEffect, useState } from 'react';
import '../../utils/styles/Navbar/Nav.scss';
import { useTranslation } from 'react-i18next'; // Vérifiez que c'est bien importé
import { FaInstagram, FaLinkedin, FaTiktok } from 'react-icons/fa';
import { useNavigate, useLocation } from 'react-router-dom';

const Nav = ({ isClosing, onLinkClick }) => {
  const { t, i18n } = useTranslation(); // Correctement utilisé pour obtenir t et i18n
  const [contentVisible, setContentVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isClosing) {
      const timer = setTimeout(() => {
        setContentVisible(true);
      }, 300);
      return () => clearTimeout(timer);
    } else {
      setContentVisible(false);
    }
  }, [isClosing]);

  const changeLanguage = (lng) => {
    if (i18n && i18n.changeLanguage) { // Ajoutez une vérification pour vous assurer que i18n.changeLanguage existe
      i18n.changeLanguage(lng);
    } else {
      console.error("i18n.changeLanguage is not a function");
    }
  };

  const handleNavigation = (sectionId) => {
    if (location.pathname !== '/') {
      navigate('/');
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 500);
    } else {
      scrollToSection(sectionId);
    }
    onLinkClick();
  };

  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const navigateToMentionsLegal = () => {
    navigate('/mentions-legal');
    onLinkClick();
  };

  return (
    <div className={`nav ${isClosing ? 'closing' : ''}`}>
      <div className={`nav-content ${contentVisible ? 'visible' : ''}`}>
        <div className="nav-header">
          <h1>KOLA.fr</h1>
          <div className="language-switch">
            <span onClick={() => changeLanguage('fr')}>Fr</span> | <span onClick={() => changeLanguage('en')}>En</span>
          </div>
        </div>
        <ul className="nav-links">
          <li onClick={() => handleNavigation('header')}>{t('Home')}</li>
          <li onClick={() => handleNavigation('about')}>{t('About')}</li>
          <li onClick={() => handleNavigation('projects')}>{t('Projects')}</li>
          <li onClick={() => handleNavigation('contact')}>{t('Contact')}</li>
        </ul>
        <div className="nav-footer">
          {/* <div className="social-icons">
            <FaInstagram />
            <FaLinkedin />
            <FaTiktok />
          </div> */}
          <p onClick={navigateToMentionsLegal}>{t('mentions légales')}</p> {/* Naviguer vers la page Mentions légales */}
        </div>
      </div>
    </div>
  );
};

export default Nav;
